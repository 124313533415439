import PropTypes from "prop-types"
import React, { Fragment } from "react"

import Logo from "../../images/logo-light.svg"
import Kartos from "../../images/kartos-logo.svg"

import {
  FooterTop,
  FooterBtm,
  FooterNavWrap,
  FooterLogo,
  FooterNav,
  FooterNavItem,
  KartosLogo,
  FooterLogoWrap,
  FooterCopy,
  FooterLink
} from './styles'

import {
  Wrapper,
  PrintWrap
} from '../styled'


class Footer extends React.Component {

  render() {
    const today = new Date();
    const year = today.getFullYear();
    return (
        <PrintWrap>
          <footer role="contentinfo">
              <FooterTop>
                  <Wrapper>
                      <FooterNavWrap>
                          <FooterLogo src={Logo} />
                          {/* <FooterNav>
                              <FooterNavItem target="_blank" href="https://google.com">
                                      Privacy Policy
                              </FooterNavItem>
                              <FooterNavItem target="_blank" href="https://google.com">
                                      Licensing
                              </FooterNavItem>
                              <FooterNavItem target="_blank" href="https://google.com">
                                  Patient Information
                              </FooterNavItem>
                              <FooterNavItem target="_blank" href="https://google.com">
                                      Terms of Use
                              </FooterNavItem>
                              <FooterNavItem target="_blank" href="https://google.com">
                                      Contact Us
                              </FooterNavItem>
                          </FooterNav> */}
                      </FooterNavWrap>
                  </Wrapper>
              </FooterTop>
              <FooterBtm>
                  <Wrapper>
                      <FooterLogoWrap>
                          <FooterCopy>
                              &copy; {year} Kartos Therapeutics, Inc. All rights reserved. <a href="https://kartosthera.com/terms-and-policy" target="_blank">Terms of Use and Privacy Policy</a>
                          </FooterCopy>
                          <FooterLink href="mailto:boreas-trial@kartosthera.com">Contact Us</FooterLink>
                      </FooterLogoWrap>
                  </Wrapper>
              </FooterBtm>
        </footer>
      </PrintWrap>
    )
  }
}

export default Footer;

Footer.propTypes = {
  style: PropTypes.object,
  theme: PropTypes.object
}
