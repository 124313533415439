export const theme = {
  colors: {
    white: "#FFFFFF",
    black: "#000000",
    copy: "#21333A",
    blueOnGray: "#0C568A",
    blue: "#0C568A",
    teal: "#1385D6",
    robins: "#1385D6",
    powder: "#B8D4E2",
    pearl: "#E4EAE7",
    gold: "#D3A509",
    yellow: "#FFD844",
    gray: "#F0F0F0",
    border: "#F4F4F4",
    modal: "rgba(35,31,32,.45)",
    track: "rgba(255,255,255,.3)",
    border: "#B9BDC5",
    hr: "#bfbfc1",
    label: "#4D4F53",
    select: "#BDC1C8",
    location: "#DFDFDF",
    newRobins: "#5BEAFF",
  },
  shadows: {
    nav: "0 2px 6px 1px rgba(0,0,0,0.11)",
  },
  fonts: {
    copy: "'Roboto', Helvetica, arial, sans-serif",
    heading: "'Roboto Slab', serif",
  },
  weights: {
    light: "300",
    reg: "400",
    semi: "600",
    bold: "700",
    extra: "800",
  },
  fontSizes: {
    tiny: ".75rem",
    sm: ".875rem",
    reg: "1rem",
    regPlus: "1.125rem",
    h1: "3rem",
    title: "3.125rem",
    h2: "1.5rem",
    h3: "1rem",
    footer: ".9375rem",
  },
  sizing: {
    maxWidth: "960px",
  },
  leading: {
    tiny: ".5px",
    mini: "1px",
    small: "1.5px",
    lg: "2px",
    xl: "3px",
  },
  breakpoints: [414, 768, 992, 1200, 1400, 1600, 1800],
}

export const mq = theme.breakpoints.map(bp => `@media (min-width: ${bp}px)`)
