import PropTypes from "prop-types"
import React, { Fragment } from "react"
import { css } from "@emotion/core"
import { Slider } from 'react-burgers'
import Toggle from 'react-toggle'
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import langCode from "iso-639-1";

import Nav from "../nav"
import StyledModal from '../styledModal'

import Hero from "../../images/hero.jpg"
import Logo from "../../images/logo-dark.svg"
import LightLogo from "../../images/logo-light.svg"
import Close from '../../images/modal-close.inline.svg'


import {
  HeaderWrap,
  HeaderLogo,
  HeaderTitle,
  HeaderSub,
  HeaderCta,
  HeaderShim,
  MobHeader,
  MobWrap,
  MobLogo,
  ToggleWrap,
  ToggleLabel,
  ToggleContent,
  ToggleHolder,
  ModalBody,
  ModalBtns,
  ModalClose,
  ModalHeader,
  ModalCopy,
  ModalBtn,
  DropdownWrap
} from './styles'

import {
  Wrapper,
  PrintWrap
} from '../styled'
import _ from "lodash"

const heroBg = css`
  background-image: url(${Hero});
`;
const countries = require("i18n-iso-countries");

class Header extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      mobNav: false,
      width: 0,
      modalIsOpen: false,
      patientToggle: false
    };

    this.handleToggle = this.handleToggle.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.toggleBurger = this.toggleBurger.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.toHCP = this.toHCP.bind(this);
    this.returnSubTitles = this.returnSubTitles.bind(this)
  }

  componentDidMount() {
    this.setState({ width: window.innerWidth });
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({
      modalIsOpen: false
    });
  }

  handleToggle() {
    const { lang, page } = this.props;
    let dLang = lang === "en-us"? "" : lang;

    if (page === 'hcp') {
      window.location.href = '/' + dLang;
    } else {
      this.setState({ modalIsOpen: true });
    }
  }

  handleResize() {
    const { width } = this.state || {};
    this.setState({ width: window.innerWidth });
    if (width > 767) {
      this.setState({ mobNav: false })
    }
  }

  toggleBurger() {
    const { mobNav } = this.state;
    const width = window.innerWidth;
    if (width < 992) {
      this.setState({
        mobNav: !mobNav
      })
    }
  }

  returnLangs(lang, sections){
    let langs = [];
    
    if(sections !== undefined ){
      _.forEach(sections[0].Languages, function(language){
        let list = language.lang.split("-");
        let iso639 = list[0];
        let iso3166 = list[1];
        let name = langCode.getNativeName(language.lang)
        if(_.indexOf(langs, name) < 0){
          countries.registerLocale(require("i18n-iso-countries/langs/" + iso639 + ".json"));
          langs.push({"value":iso639 + "-" + iso3166, "label":langCode.getNativeName(iso639) + " - " + countries.getName(iso3166, iso639)});
        }
      })
    }
    return langs;
  }

  handleChange(e){
    let dest = window.location.origin;
    let lang = e.value;

    //Check Current lang setting
    if(lang !== "en-us"){
      dest += "/" + lang;
    }

    if(window.location.pathname.includes("hcp")){
      dest += "/hcp";
    }
    
    window.location.href = dest;
  }

  toHCP(){
    const { lang } = this.props;
    let dLang = lang === "en-us"? "" : "/" + lang;

    window.location.href = window.location.origin + dLang + "/hcp";
  }

  returnPopup(modalIsOpen){
    const { lang, sections } = this.props;
    let healthConfrim = _.find(_.find(sections, {"section_title": "popup"}).Languages, {"lang": lang});
    let text = _.split(healthConfrim.section.content, "\n");
    let rawButtonText = text[_.findIndex(text, function(t){
      return t.includes("]<")
    })];
    let choices = _.split(/\<(.*?)[\>\)]/.exec(rawButtonText)[1], "/");

    return (
      <StyledModal active={true} modalIsOpen={modalIsOpen} closeModal={this.closeModal}>
        <ModalBody>
          <ModalClose onClick={this.closeModal}><Close /></ModalClose>
          <ModalHeader>{healthConfrim.section.title}</ModalHeader>
          <ModalCopy className="is-full">{text[0]}</ModalCopy>
          <ModalBtns>
            <ModalBtn onClick={this.toHCP}>{choices[0]}</ModalBtn>
            <ModalBtn onClick={this.closeModal}>{choices[1]}</ModalBtn>
          </ModalBtns>
        </ModalBody>
      </StyledModal>
    )
  }
  returnSubTitles(rawText){
    let subs = [];
    _.forEach(rawText, function(text){
      let index = text.indexOf("###");
      if(index >= 0){
        subs.push(<HeaderTitle>{text.substring(3)}</HeaderTitle>);
      }else{
        subs.push(<HeaderSub>{text}</HeaderSub>);
      }
    })

    return subs;
  }

  render() {
    const { theme, page, lang, sections, dir } = this.props;
    const { mobNav, modalIsOpen, patientToggle } = this.state || {};
    const burger =  mobNav ? theme.colors.black : theme.colors.white;
    let options = this.returnLangs(lang, sections);
    let toggSplit, rawText;
    if(page !== "non"){
      let headerC = _.find(_.find(sections, {"section_title": "header"}).Languages, {"lang": lang});

      rawText = _.split(headerC.section.content, "\n");
      rawText = _.remove(rawText,function(n) {
        return n;
      })
      let toggRaw = /\<(.*?)[\>\)]/.exec(rawText[0]);
      toggSplit = _.split(toggRaw[1], "|");
    }else{
      rawText = ["", "A Clinical Trial For Patients with", "Myelofibrosis"]
      toggSplit = ["Patient or Caregiver", "Healthcare Professional"]
    }

    return (
      <PrintWrap>
        {
          this.props.page !== "non"?
          <ToggleWrap className={dir === "rtl"? "rtl" : ""}>
            <DropdownWrap>
              {
                options.length === 0? 
                null:
                <Dropdown 
                  options={options} 
                  value={langCode.getNativeName(lang.substring(0,2))}
                  onChange={(e) => this.handleChange(e)}
                />
              }
            </DropdownWrap>
            <ToggleHolder>
              <ToggleContent>
                <ToggleLabel className="is-mob">Non-HCP</ToggleLabel>
                <ToggleLabel>{toggSplit[0]}</ToggleLabel>
                <Toggle
                  icons={false}
                  defaultChecked={page === 'hcp' ? true : false}
                  checked={page === 'hcp' ? true : false}
                  onChange={this.handleToggle}
                />
                <ToggleLabel className="is-mob">HCP</ToggleLabel>
                <ToggleLabel>{toggSplit[1]}</ToggleLabel>
              </ToggleContent>
            </ToggleHolder>
          </ToggleWrap>: 
          null
        }
        <Nav
          menu={page}
          mobNav={mobNav}
          handleClick={this.toggleBurger}
          sections={this.props.sections}
          lang={this.props.lang}
          dir={dir}
        />
        <MobHeader className={mobNav ? '' : 'is-sticky'}>
          <Wrapper>
            <MobWrap>
              <MobLogo src={LightLogo} />
               <Slider
                padding=".5rem"
                zIndex={100000}
                lineHeight={3}
                lineSpacing={7}
                active={mobNav}
                onClick={this.toggleBurger}
                color={burger} />
            </MobWrap>
          </Wrapper>
        </MobHeader>
        {/* Page Title */}
        <HeaderWrap css={heroBg} role="banner">
          <HeaderShim>
            <Wrapper>
              <HeaderLogo src={LightLogo} />
              {this.returnSubTitles(rawText.slice(1))}
            </Wrapper>
          </HeaderShim>
        </HeaderWrap>
        {/* Popup */}
        {
          page === "home"?
            this.returnPopup(modalIsOpen):
            null
        }
      </PrintWrap>
    )
  }
}

export default Header;

Header.propTypes = {
  style: PropTypes.object,
  theme: PropTypes.object
}
