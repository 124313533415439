import PropTypes from "prop-types"
import React, { Fragment } from 'react';

import Modal from 'react-modal';

import {
	styledModal
} from './styles';



class StyledModal extends React.Component {

	render() {
		const {
			children,
			modalIsOpen,
			closeModal,
			active
		} = this.props;

		return (
			<Fragment>
				<Modal
					css={styledModal}
					isOpen={modalIsOpen}
					onRequestClose={closeModal}
					className={active ? 'styled-modal is-on-top' : 'styled-modal'}
					closeTimeoutMS={500}
				>
					{children}
				</Modal>
			</Fragment>
		);
	}
}

export default StyledModal

StyledModal.propTypes = {
	children: PropTypes.node,
	closeModal: PropTypes.func,
	active: PropTypes.bool,
	modalIsOpen: PropTypes.bool
}