import emotionNormalize from "emotion-normalize"
import { css } from "@emotion/core"
import { theme, mq } from "../../util/theme"

export const globalStyles = css`
  ${emotionNormalize}

  html {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    font-size: 16px;
  }
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
  body {
    font-family: ${theme.fonts.copy};
    overflow-x: hidden;
  }
  figure {
    margin: 0;
    padding: 0;
  }
  img {
    max-width: 100%;
  }

  .main {
    ${mq[2]} {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      max-width: 960px;
      margin: auto;
    }
    &.non{
      display: block;
      height: 60vh;
    }
    @media print{
      max-width: fit-content;
      margin: 0;
      ${mq[2]} {
        display: block;
        max-width: none;
      }
    }
  }

  .Burger {
    z-index: 1000;
  }

  .BurgerActive {
    position: fixed;
    right: 1.5rem;
    top: 4rem;
  }
  .ReactModal__Body {
    &--open {
      max-height: 100vh;
      overflow: hidden;
    }
  }
  .ReactModal__Overlay {
    transition: opacity 0.75s ease-in-out;
    opacity: 0;
    z-index: 999;
    pointer-events: none;
    &--after-open {
      opacity: 1;
      transition: all 0.75s ease-in-out;
      background-color: transparent !important;
      overflow-y: scroll;
    }
    &--before-close {
      opacity: 0;
    }
  }
  .ReactModal__Content {
    transition: transform 0.3s ease-in, opacity 0.3s ease-in-out;
    transform: scale(1.5);
    will-change: transform, opacity;
    transform-origin: 50% 50%;
    opacity: 0;
    &--after-open {
      opacity: 1;
      transform: scale(1);
    }
    &--before-close {
      opacity: 0;
      transform: scale(1.25);
    }
  }
  .react-toggle {
    touch-action: pan-x;
    display: inline-block;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
  }

  .react-toggle-screenreader-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .react-toggle--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    transition: opacity 0.25s;
  }

  .react-toggle-track {
    width: 28px;
    height: 14px;
    padding: 0;
    border-radius: 30px;
    background-color: ${theme.colors.track};
    transition: all 0.2s ease;
  }

  .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: ${theme.colors.track};
  }

  .react-toggle--checked .react-toggle-track {
    background-color: ${theme.colors.track};
  }

  .react-toggle--checked:hover:not(.react-toggle--disabled)
    .react-toggle-track {
    background-color: ${theme.colors.track};
  }

  .react-toggle-track-check {
    position: absolute;
    width: 14px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    left: 8px;
    opacity: 0;
    transition: opacity 0.25s ease;
  }

  .react-toggle--checked .react-toggle-track-check {
    opacity: 1;
    transition: opacity 0.25s ease;
  }

  .react-toggle-track-x {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    right: 10px;
    opacity: 1;
    transition: opacity 0.25s ease;
  }

  .react-toggle--checked .react-toggle-track-x {
    opacity: 0;
  }

  .react-toggle-thumb {
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    position: absolute;
    top: 1px;
    left: 1px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background-color: ${theme.colors.newRobins};
    transition: all 0.25s ease;
  }

  .react-toggle--checked .react-toggle-thumb {
    left: 14px;
    border-color: ${theme.colors.newRobins};
  }

  .react-toggle--focus .react-toggle-thumb {
    //box-shadow: 0px 0px 2px 3px #0099E0;
  }

  .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
    //box-shadow: 0px 0px 5px 5px #0099E0;
  }

  .is-centered {
    display: block;
    margin: auto;
  }
`
