import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { mq } from '../../util/theme'

export const Wrapper = styled.div`
  max-width: ${props => props.theme.sizing.maxWidth};
  margin: auto;
  padding: 0 1.5rem;
  position: relative;
  ${mq[2]} {
    padding: 0;
    &.nav{
      max-width: 1010px;
    }
  }
  &.rtl{
    margin: auto 3rem;
    select.results{
      padding: 0.75rem 1.5rem;
      margin-left:1em;
    }
    select.radius{
      padding: 0.75rem 1.5rem;
      margin:0 1em;
    }
    select{
      padding: 0.75rem 3rem;
    }
    .name,
    .email,
    .postcode{
      margin-left: 1rem;
    }
    .is-radio,
    .is-check{
      label::before{
        margin-left: 10px;
        margin-right: 0px;
      }
    }
    .is-check{
      label::after{
        left: 34.45rem;
      }
    }
    #committee{
      section{
        div{
          margin: 0 0 3rem 1rem;
          &:nth-of-type(2n) {
            margin-left: 0;
          }
          ${mq[1]} {
            &:nth-of-type(2n) {
              margin-left: 1rem;
            }
          }
        }
      }
    }

    li{
      &:before{
        left: auto;
        right: -5px;
      }
      padding-left: 0;
      padding-right: 1.5rem;
    }
  }

  @media print{
    max-width: none;
    margin: 0;
    padding: 0;
    ${mq[2]} {
      padding: 0;
    }
  }
  .print-none{
    @media print{
      display: none;
    }
  }

  .print-only{
    display: none;
    @media print{
      display: block;
    }
  }
`;


export const Title = styled.h1`
  color: ${props => props.theme.colors.copy};
  font-size: ${props => props.theme.fontSizes.title};
  font-family: ${props => props.theme.fonts.copy};
  font-weight:  ${props => props.theme.weights.bold};
  margin: 0 0 2rem;
  &.non{
    margin: 2rem 0 1rem;
  }
`;

export const Copy = styled.p`
  color: ${props => props.theme.colors.copy};
  font-size: ${props => props.theme.fontSizes.reg};
  font-family: ${props => props.theme.fonts.copy};
  font-weight:  ${props => props.theme.weights.reg};
  margin: 0 0 2rem;

  @media print{
    font-size: 13px;
    line-height: 1.69;
  }
`;

export const Btn = styled.a`
  color: ${props => props.theme.colors.copy};
  background: ${props => props.theme.colors.robins};
  font-size: ${props => props.theme.fontSizes.h3};
  font-weight:  ${props => props.theme.weights.extra};
  text-transform: uppercase;
  text-align: center;
  max-width: 225px;
  text-decoration: none;
  padding: 1rem 0;
  width: 100%;
  margin: 2rem 0;
  cursor: pointer;
  &:hover,
  &:active,
  &:focus {
    background: ${props => props.theme.colors.powder};
  }
  &.non{
    padding: 1rem;
    display: block;
  }
`;

export const PrintWrap = styled.div`
  @media print{
    display:none;
  }
`;