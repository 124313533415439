/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import 'babel-polyfill'
import React from "react"
import PropTypes from "prop-types"
import { Global } from "@emotion/core"
import { ThemeProvider } from 'emotion-theming'
import { theme } from '../../util/theme'

import Header from "../header"
import Footer from "../footer"

import {
  globalStyles
} from './styles'

class Layout extends React.Component {

  render() {
    const { children, page, dir } = this.props;
    return (
      <ThemeProvider theme={theme}>
        <Global styles={globalStyles} />
        <>
          <Header page={page} theme={theme} lang={this.props.lang} sections={this.props.sections} dir={dir}/>
          <main className={ page == "non"? "main non": "main"} role="main">{children}</main>
          <Footer theme={theme} />
        </>
      </ThemeProvider>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
