import PropTypes from "prop-types"
import React from "react"
import _ from "lodash"
import { Link } from "react-scroll";

import {
  NavWrap,
  NavList,
  NavItem,
  NavLink
} from './styles'

import {
  Wrapper
} from '../styled'

class Nav extends React.Component {

  constructor(props) {
    super(props);
  }

  createNavItems(lang, page){
    let items = [];

    //console.log(this.props.sections);
    _.forEach(this.props.sections, function(section){
      let content = _.find(section.Languages, {"lang": lang});

      //console.log(content)
      if(typeof content.section.menu_title !== "undefined"){
        if(!(section.section_title=== "popup" || section.section_title === "sidebar")){
          if(section.section_title === "eligibility" && page === "home"){
            items.push({
              text: content.section.menu_title,
              href: section.section_title,
              className: "is-blue"
            })
          }else{
            items.push({
              text: content.section.menu_title,
              href: section.section_title
            })
          }
        }
      }
    })

    return items;
  }

  returnMenu (navItems, handleClick){
    let menu = [];

    _.forEach(navItems, function(item){
      const {
        text,
        href,
        className
      } = item;
      menu.push(
        <NavItem className={className} key={href}>
          <Link
            css={NavLink}
            activeClass="is-active"
            to={href}
            spy={true}
            smooth={true}
            duration={500}
            offset={-50}
            onClick={handleClick}
          >{ text }</Link>
        </NavItem>
      )
    })

    return menu;
  }

  render() {
    const { mobNav, menu, handleClick, dir } = this.props;
    const navItems =  this.createNavItems(this.props.lang, menu);

    return (
      <NavWrap className={mobNav ? `is-active is-open` : ``}>
        <Wrapper className={"nav"}>
          <NavList className={dir}>
            { this.returnMenu(navItems, handleClick) }
          </NavList>
          {/* <NavCta target="_blank" href="https://google.com">Register</NavCta> */}
        </Wrapper>
      </NavWrap>
    )
  }
}

export default Nav;

Nav.propTypes = {
  style: PropTypes.object,
  theme: PropTypes.object,
  handleClick: PropTypes.func
}
