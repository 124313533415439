import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { mq, theme } from '../../util/theme'

export const NavWrap = styled.nav`
  transition: all 0.5s ease-in-out;
  pointer-events: none;
  overflow: hidden;
  background: transparent;
  transform: translate3d(100vw, 0, 0);
  position: fixed;
  top: 0;
  pointer-events: all;
  width: 100%;
  height: 100vh;

  &.is-active {
    transform: translate3d(0, 0, 0);
  }

  &.is-open {
    height: 100vh;
    pointer-events: all;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: ${props => props.theme.colors.white};
    width: 100%;
    z-index: 100;
    overflow: hidden;
    overflow-y: scroll;
    padding-top: 2.75rem;
  }

   ${mq[2]} {
    transform: translate3d(0, 0, 0);
    position: relative;
    display: block;
    width: 100%;
    overflow: visible;
    height: auto;
    position: sticky;
    top: 0;
    background: ${props => props.theme.colors.white};
    box-shadow: ${props => props.theme.shadows.nav};
    z-index: 100;
    padding: 0;
  }
  @media print{
    display: none;
  }
`;

export const NavList = styled.ul`
    list-style: none;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 6rem auto 0;
    padding: 0;
    flex-direction: column;
    max-width: 200px;
    width: 100%;

    ${mq[2]} {
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      margin: 0;
      max-width: 100%;
      li{
        margin: 0;
        padding: 0 .25rem;
        width: auto;

        &:after {
          content: "";
          height: 35px;
          width: 1px;
          background: ${props => props.theme.colors.copy};
          position: absolute;
          display: block;
          top: 50%;
          -webkit-transform: translateY(-50%);
          -moz-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
          right: -2px;
        }
        &:last-of-type {
          &:after {
              ${mq[2]} {
                  display: none;
              }
          }
        }
      }
      &.rtl{
        li{
        &:last-of-type {
          &:after {
            ${mq[2]} {
                display: block;
            }
          }
        }
        &:first-of-type {
          &:after {
            ${mq[2]} {
                display: none;
            }
          }
        }
      }
    }
  }

  @media print{
    list-style: disk;
  }
`;

export const NavItem = styled.li`
    font-family: ${props => props.theme.fonts.copy};
    font-size: 0.9rem;
    text-transform: uppercase;
    margin-bottom: 2rem;
    display: flex;
    font-weight: ${props => props.theme.weights.semi};
    position: relative;
    padding-bottom: 1.5rem;
    width: 100%;
    &.is-blue {
      ${mq[2]} {
        a {
          color: ${props => props.theme.colors.robins} !important;
        }

      }
      a {
        &:hover,
        &:active,
        &:focus,
        &.is-active {
          background: ${props => props.theme.colors.white};
          color: ${props => props.theme.colors.copy};
        }
      }
    }

    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 1px;

    }
`;

export const NavLink = css`
    color: ${theme.colors.copy};
    text-decoration: none;
    display: block;
    position: relative;
    cursor: pointer;
    letter-spacing: .5px;

    &:hover,
    &:active,
    &:focus,
    &.is-active {
      &:after {
        content: "";
        background: ${theme.colors.robins};
        position: absolute;
        left: -2rem;
        bottom: -1.5rem;
        top: -1.5rem;
        width: 7px;
        pointer-events: none;
        display: block;
        ${mq[2]} {
            left: 0;
            right: 0;
            width: 100%;
            height: 7px;
            top: auto;
            bottom: 0rem;

        }
      }
    }

    ${mq[2]} {
      color: ${theme.colors.copy};
      text-align: center;
      max-width: 100%;
      width: 100%;
      padding: 1.25rem .5rem;
    }
`;

export const NavCta = styled.a`
    display: block;
    max-width: 210px;
    width: 100%;
    background: ${props => props.theme.colors.yellow};
    color: ${props => props.theme.colors.black};
    font-size: ${props => props.theme.fontSizes.h3};
    font-family: ${props => props.theme.fonts.copy};
    font-weight:  ${props => props.theme.weights.bold};
    text-decoration: none;
    text-align: center;
    padding: 1rem 0;
    text-transform: uppercase;
    margin: auto;
    &:hover {
      background: ${props => props.theme.colors.robins};
    }
    ${mq[2]} {
      display: none;
    }
`;
