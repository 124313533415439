import { css } from "@emotion/core"
import { theme } from '../../util/theme'

export const styledModal = css`
  min-height: 100vh;
  width: 100%;
  background: ${theme.colors.modal};
  padding: 2rem 0;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &.is-on-top {
    display: flex;
    z-index: 10000;
    pointer-events: all;
  }
`;
