import styled from "@emotion/styled"
import { mq } from '../../util/theme'

export const FooterTop = styled.section`
  background:  ${props => props.theme.colors.copy};
  padding: 1.5rem 0;
  @media print{
    margin-right: 0;
  }
`;

export const FooterNavWrap = styled.div`
    ${mq[1]} {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
`;

export const FooterLogoWrap = styled.div`
    ${mq[1]} {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
`;

export const FooterLogo = styled.img`
    display: block;
    max-width: 200px;
    width: 100%;
    margin: 0 0 2rem -2rem;
    ${mq[1]} {
        margin: 0 0 0 -2rem;
    }
`;

export const FooterNav = styled.nav`
    ${mq[1]} {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 680px;
    }
`;

export const FooterNavItem = styled.a`
    display: flex;
    margin-bottom: 1rem;
    font-size: ${props => props.theme.fontSizes.sm};
    color: ${props => props.theme.colors.white};
    font-weight:  ${props => props.theme.weights.bold};
    text-decoration: none;
    &:hover {
        color: ${props => props.theme.colors.yellow};
    }
    ${mq[1]} {
        margin-bottom: 0;
        margin-right: 1rem;
        &:after {
            content: '';
            height: 15px;
            width: 1px;
            background: ${props => props.theme.colors.white};
            display: block;
            margin-left: 1rem;
        }
        &:last-of-type {
             &:after {
                 display: none;
             }
        }
    }
`;

export const FooterBtm = styled.section`
  background:  ${props => props.theme.colors.gray};
  padding: .5rem 0 2rem;
  ${mq[1]} {
     padding: .5rem 0;
  }
  @media print{
    margin-right: 0;
  }
`;

export const FooterCopy = styled.p`
  font-size: ${props => props.theme.fontSizes.footer};
  a {
    color: ${props => props.theme.colors.copy};
    &:hover {
        color: ${props => props.theme.colors.blue};
    }
  }
`;

export const FooterLink = styled.a`
  font-size: ${props => props.theme.fontSizes.footer};
  font-weight:  ${props => props.theme.weights.bold};
  color: ${props => props.theme.colors.copy};
  text-decoration: none;
  &:hover {
    color: ${props => props.theme.colors.blue};
  }
`;

